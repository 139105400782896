import React, { useEffect, useState } from "react";
import axios from "axios";
import { CreditCardIcon } from "@heroicons/react/solid";
import logo from '../assets/images/depo_logo.png'
import exampleRU from '../assets/images/exampleRU.png'
import info from '../assets/images/info_icon.png'
import {useParams} from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL
const Form = () => {
  const [inputPlateNumber, setInputPlateNumber] = useState("");
  let {parkingUid} = useParams();
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(0)
  const [click, setClick] = useState(false)
  const [responseErrorMessageData, setResponseErrorMessageData] = useState("");


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseURL}/ru/bms/send`,
        {
          plateNumber: inputPlateNumber,
          parkingUid: parkingUid,
          returnUrl: window.location.origin,
        }
      );
      setLoading(false);
      openInCurrentTab(response.data.url);
      setResponseErrorMessageData("");
    } catch (error) {
      setLoading(false);
      console.log("Error: ",error)
      const errorMessage = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Произошла ошибка при отправке данных";
      setResponseErrorMessageData(errorMessage);
    }
  };

  const openInCurrentTab = (url) => {
    window.location.assign(url);
  };
  const handleClick = () => {
    if(!click && count < 1){
      setClick(true);
      setCount(1)
    }
    
  }
  return (
    <div className="hero">
      <div className={`min-h-full flex items-center ${click ? 'pt-1' : 'pt-26'} justify-center py-12 px-4 sm:px-6 lg:px-8`}>
        <div className="max-w-sm w-full space-y-8">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                      <div className="modal-header" style={{display: 'flex', justifyContent: 'end'}}>
                          <button  data-bs-dismiss="modal" aria-label="Close" style={{cursor: 'pointer', width: '30px', height: '30px'}} onClick={() => setClick(false)}>X</button>
                      </div>
                      <div className="modal-body" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                          <img src={exampleRU} alt="Example of plate numbers" style={{width: '300px', height: '300px'}} />
                      </div>
                      
                  </div>
              </div>
          <div>
            
            <h2 className="mt-6 dark:text-white text-center text-3xl font-extrabold text-gray-900">
              Оплата парковки
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={handleSubmit}
            method="POST"
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md  -space-y-px">
              <div>
                <label htmlFor="account" className="sr-only">
                  Номер авто
                </label>
                <div style={{position: 'relative'}}>
                  <span style={{
                    marginLeft: '85%',
                    padding: '10px',
                    position: 'absolute',
                    zIndex: 2,
                    
                  }} onClick={() => setClick(true)}><img src={info} alt="Info card icon" /></span>
                  <input
                    id="plateNumber"
                    name="plateNumber"
                    type="text"
                    autoComplete="plateNumber"
                    required
                    style={{borderWidth: '3px'}}
                    className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Гос. номер в формате C065MK78"
                    value={inputPlateNumber}
                    onClick={(e) => handleClick()}
                    onChange={(e) => {  
                      setInputPlateNumber(e.target.value);
                    }}
                  />
                  <span style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginTop: '10px',
                    fontWeight: 'bolder',
                  }}>Время на выезд после оплаты 15 минут</span>
                </div>
                
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <CreditCardIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                {loading ? (
                  <svg
                    role="status"
                    className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                ) : (
                  <span>Оплатить</span>
                )}
              </button>
            </div>
            {responseErrorMessageData !== undefined &&
              responseErrorMessageData.length > 0 && (
                <div
                  className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                  role="alert"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 inline w-5 h-5 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <div></div>
                  <span className="font-medium">
                    {responseErrorMessageData}
                  </span>
                </div>
              )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default Form;
